/**
 * Script para login.
 * */

jQuery.noConflict();
var $ = jQuery;

/**
 * Tornar o texto do campo usuário em maiúsculo.
 
function loginUpper() {
	
	$('#usuario').change(function() {
		this.value = this.value.toUpperCase();
	});
	
}
*/

/**
 * Verificação de campos
 
function verificarCampo() {
	
	$('#usuario').change(function() {
		verifUsuario( $(this).val() );
	});
	
	$('form').submit(function(e) {
		
		if(!verifUsuario( $('#usuario').val() )) {
			e.preventDefault();
			return false;
		}
		
	});
	
	/**
	 * Verifica campo Usuário.
	 * 
	 * @param {type} valor
	 * @returns {Boolean}
	 
	function verifUsuario(valor) {
		
		if( valor.length > 10 ) {
			$('.alert')
				.empty()
				.html('Apenas 10 caracteres são permitidos para o campo <b>Usuário</b>.')
				.slideDown();
			return false;
		}
		else {
			$('.alert').slideUp();
			return true;
		}
		
	}
}
*/

/**
 * Retorna uma string sem espaços do valor enviado.
 * @param {string} valor
 */
function trim_null(valor){
	if(valor !== null && typeof valor !== 'undefined'){
		valor = ((valor+'').trim());
	}else{
		valor = '';
	}
	return valor;
}

function renderBannerLogin(){

	var banner = $('#banner').val();

	if(trim_null(banner) != ''){

		$('#foto-modelo').attr('src', banner);

	} else {

		$('#logo-section').css('background', 'none');

	}

}

// function renderLoginBanner(){

// 	var banner = $('#banner').val();
// 	var json_banner = JSON.parse(banner);

// 	if(trim_null(json_banner.IMAGE_URL) != ''){

// 		$('#logo-section').attr('style', `background-image: url(${JSON.stringify(json_banner.IMAGE_URL)})`);

// 	}

// }


(function($) {
	$(function() {		
		sessionStorage.clear();

		var urlhost = window.location.origin;

		if(window.location.href !== urlhost + '/portal' && window.location.href !== urlhost + '/portal-colaborador'){
		
			localStorage.removeItem('ngStorage-menus');
			localStorage.removeItem('ngStorage-grupos');
			
		}
			
		for(var i = localStorage.length - 1; i >= 0; i--) {
			if(localStorage.key(i).indexOf('TBHISTORICO') != -1) { 
				localStorage.removeItem(localStorage.key(i));				
			}
		}
		
		// function setCookie(cname, server) {
		// 	var name = cname + "=";
		// 	var decodedCookie = decodeURIComponent(document.cookie);
		// 	var ca = decodedCookie.split(';');
		// 	for(var i = 0; i <ca.length; i++) {
		// 		var c = ca[i];

		// 		while (c.charAt(0) == ' ') {
		// 			c = c.substring(1);
		// 		}

		// 		if (c.indexOf(name) == 0) {
		// 			//console.log(c);
		// 			document.cookie = name + "."+server+";expires=Thu, 01 Jan 3000 00:00:00 GMT";
		// 			return c.substring(name.length, c.length);
		// 		}
		// 	}
		// 	return "";
		// }

		$(document).on('click','.gravar_cookie_login', function(){
			//setCookie("ANIVERSARIO", 1);
			document.cookie= "ANIVERSARIO=" + "."+1+";expires=Thu, 01 Jan 3000 00:00:00 GMT";
			document.cookie= "NOTIFICAR_MODULOS=" + "."+1+";expires=Thu, 01 Jan 3000 00:00:00 GMT";
		})

		//localStorage.clear();
		//loginUpper();
		//verificarCampo();		
	});
})(jQuery);